.slick-disabled {
  opacity: 0;
  pointer-events:none;
}

.slick-slider .slick-next {
  right: -40px;
  top: 170px;
}

.slick-slider .slick-next:before {
  content: none;
}

.slick-slider .slick-prev {
  left: -30px;
  top: 150px;
}

.slick-list {
  margin: 0 -10px;
  padding: 10px 0;
}
.slick-slide > div {
  padding: 0 10px;
  height: 100%; /* This helps to make all slides equal height */
}

.slick-slide > div > div {
  height: 95%; /* This make all slides equal height */
}

.slick-track {
  margin-left: 0;
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}
